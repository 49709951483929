export function ready(fn: () => void) {
  addEventListener("turbo:load", fn);
}

export function csrf_token(): string | undefined {
  return document.head.querySelector<HTMLMetaElement>("meta[name=csrf-token]")
    ?.content;
}

export function fetchHeaders(): HeadersInit {
  return {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "X-CSRF-Token": csrf_token()!,
  };
}

export function swapClasses(
  elem: HTMLElement,
  options: { add: string | Array<string>; remove: string | Array<string> }
) {
  // Add
  if (typeof options.add === "string") {
    elem.classList.add(options.add);
  } else {
    options.add.forEach((name) => elem.classList.add(name));
  }

  // Remove
  if (typeof options.remove === "string") {
    elem.classList.remove(options.remove);
  } else {
    options.remove.forEach((name) => elem.classList.remove(name));
  }
}

export function fadeIn(inElem: HTMLElement, outElem: HTMLElement) {
  inElem.classList.replace("d-none", "fade-in");
  outElem.classList.add("d-none");
}
